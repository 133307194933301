<template>
    <modal ref="modalDetalleCupon" titulo="Cupon" no-adicional no-cancelar>
        <section class="overflow-auto scroll-none" style="height:calc(100vh - 148px)">
            <div class="row mx-0 bg-white a-center border-bottom" style="height:50px;">
                <i class="icon-arrow-left f-20" @click="irAtras" />
                <div class="col f-18 f-600">
                    {{detalleCupon.nombre}}
                </div>
            </div>
            <div class="row mx-0 j-center">
                <div class="col-sm-12 col-11 px-0">
                    <div class="bg-white shadow-14 p-3 br-10">
                        <div class="row mx-0">
                            <div class="col px-2">
                                <p class="f-15">Expira: <span class="f-18 f-600 text-purple">{{ formatearFecha(detalleCupon.expira) }}</span> </p>
                                <p class="f-15 text-gris2">A las {{ formatearFecha(detalleCupon.expira, formatoDestino='h:mm a') }} </p>
                            </div>
                        </div>
                        <div class="row mx-0 a-center mt-2">
                            <i class="icon-cash f-15" />
                            <div class="col px-3">
                                <p class="f-15">Compra mínima: <span class="f-18 f-600 text-purple"> {{ formatNumero(detalleCupon.compra_minima) }}</span> </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-11 px-0 mt-3">
                    <div class="bg-white shadow-14 p-3 br-10">
                        <div class="row mx-0">
                            <i class="icon-coupon f-18" />
                            <div class="col px-2 f-15">
                                {{ detalleCupon.descripcion }}
                            </div>
                        </div>
                        <div class="row mx-0 mt-3">
                            <i class="icon-product f-18" />
                            <div class="col px-2 f-15">
                                {{ detalleCupon.productos }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-11 px-0 my-3">
                    <div class="bg-white br-10 shadow-14 p-2">
                        <div class="row mx-0 a-center">
                            <i class="icon-information-outline f-18" />
                            <div class="col px-2 f-12">
                                Aplica sobre el valor de los productos.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </modal>
</template>

<script>
export default {
    props: {
        detalleCupon: {
            type: Object,
            required: true,
        },
    },
    data(){
        return {
            buscar: '',
            cuponActivo: null,
            cupones: [],
        }
    },
    methods: {
        toggle(){
            this.$refs.modalDetalleCupon.toggle();
        },
        irAtras(){
            this.$emit("irAtras")
            this.$refs.modalDetalleCupon.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.button-search{
    height: 44px;
    border-radius: 20px;
}
.btn-buscar{
    background-color: var(--color-general) !important;
    position: absolute;
    right: 4px;
    height: 36px;
    top: 3px;
    border: 0px;
    border-radius: 20px;
    color: #FFFFFF;
    height: 34px;
    &:focus{
        outline: none !important;
    }
}
.card-cupon{
    width: 346px;
    height: 108px;
    border-radius: 12px;
    padding-left: 35px;
    box-shadow: 0px 2px 4px #00000014;
    z-index: 2;
    .circled-coupon{
        width: 44px;
        position: absolute;
        left: -22px;
        top: 31px;
        height: 44px;
        background-color: white;
        z-index: 1;
        border-radius: 50%;
        box-shadow: 1px 0px 4px #00000014;
        &::after{
            content: '';
            position: absolute;
            left: -24px;
            top: -1px;
            z-index: 2;
            height: 44px;
            width: 44px;
            background-color: #FFFFFF
        }
        &-active{
            @extend .circled-coupon;
            border: 1px solid var(--color-general);
        }
    }
    &.border-active{
        border: 1px solid var(--color-general);
    }
    .space-img{
        border-right: dashed 3px #F6F6F6;
    }
    .text-cupon{
        line-height: 29px;
    }
    .text-detail{
        color: #b1b9c1;
    }
    .text-fetch{
        color: #D3D7DB;
    }
}
</style>